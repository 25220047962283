import React, { useState, useEffect } from 'react'
import { Dropdown, Button, Form, Icon, Message, Divider } from 'semantic-ui-react'
import { GoogleApiWrapper } from 'google-maps-react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PhoneInput from 'react-phone-input-2'
import { propOr, isEmpty, equals } from 'ramda'
import renderIf from 'render-if'
import 'react-phone-input-2/lib/semantic-ui.css'

import LotInfoItems from '../../components/LotInfoItems'
import MapWithBounds from '../../components/MapWithBounds'
import LabelWithRequired from '../../components/Label'
import LandingBanner from './LandingBanner'
import WelcomeBanner from './WelcomeBanner'
import LogoBanner from './LogoBanner'
import LocationDetails from '../FacilityView/LocationDetails'
import { joinQueue, fetchClosestYard, getYardClosedMessage } from '../../actions/selfServeActions'
import { isBlank } from '../../utils/isBlank'

import {
  getFilteredReasons,
  numberOfLotsOptions,
  checkWithRangeForYard,
  getNavigatorGeolocation,
  skipGeoFence,
} from '../../utils'
import { getPropertyFromLocalStorage } from '../../utils/storage'
import locale from '../../utils/locale'
import { sendEventGA } from '../../utils/sendAnalytics'
import { ONLY_COUNTRIES, GOOGLE_API_KEY } from '../../constants'
import LocaledText, { getLocaledReasons } from '../../components/LocaledText'
import ErrorMessage from './../../components/ErrorMessage'
import './style.css'

const STACK = process.env.REACT_APP_STACK || 'c'

const dividerStyle = {
  marginLeft: '-15px',
  marginRight: '-15px',
}

function SelfServeForm(props) {
  const [userType, setUserType] = useState('')
  const [reasonCodes, setReasons] = useState([])
  const [numberOfLots, setNumberOfLots] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [rawPhoneNumber, setRawPhoneNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [facilityId, setFacilityId] = useState(getPropertyFromLocalStorage('facilityId'))
  const [stateCode, setStateCode] = useState(getPropertyFromLocalStorage('stateCode'))
  const [submitStatus, setSubmitStatus] = useState('pending')
  const [textAllowed, setTextAllowed] = useState(true)
  const [failureMessage, setFailureMessage] = useState('')
  const [submitPending, setSubmitPending] = useState(false)
  const [latitude, setLatitude] = useState(props.latitude) // Yard 201 43.87258,-78.74938, Yard 401 52.13823,-0.29968
  const [longitude, setLongitude] = useState(props.longitude) // Yard 303 32.74223,-96.9529, Yard 8 47.42968,-122.22502
  const [dialCode, setDialCode] = useState(1)
  const [phoneNumberValid, setPhoneValidity] = useState(true)
  const [isMember, setIsMember] = useState(false)
  const [memberRequestId, setMemberRequestId] = useState('')
  const [lotInfoItems, setLotInfoItems] = useState([
    {
      lot_number: '',
      reasons: [],
    },
  ])
  const [yardClosedMsg, setYardClosedMsg] = useState('')
  const [fetchYardClosedInProgress, setYardClosedStatus] = useState(false)
  const [yardLat, setYardLat] = useState('')
  const [yardLong, setYardLong] = useState('')
  const [locationMatchingStatus, setLocationMatchingStatus] = useState(isBlank(facilityId))
  const [locationShared, setLocationShared] = useState(isBlank(facilityId))

  const googleMaps = props.google.maps
  const markers = [
    {
      lat: yardLat,
      lng: yardLong,
    },
    {
      lat: latitude,
      lng: longitude,
    },
  ]

  const { config, userTypes, reasons, states, facilities, mapData, setMapData } = props

  const renderWholeForm = renderIf(!isEmpty(userType))

  const renderMemberField = renderIf(isMember && STACK === 'c')

  useEffect(() => {
    const { geoLocationEnabled, positionError } = props
    const sharingAllowed = geoLocationEnabled && isEmpty(positionError)
    const sharingBlocked = !geoLocationEnabled && !isEmpty(positionError) && positionError.code === 1
    if (sharingAllowed) {
      sendEventGA({ category: 'User', action: 'Allowed location sharing' })
    }
    if (sharingBlocked) {
      sendEventGA({ category: 'User', action: 'Blocked location sharing' })
    }
    // eslint-disable-next-line
  }, [props.geoLocationEnabled, props.positionError])

  useEffect(() => {
    // Check for both lat and long
    if (latitude && longitude) {
      fetchClosestYard({ latitude, longitude, countryCode: props?.countryCode }).then((response) => {
        const { status, closestFacilityId, yardStateCode } = response
        if (status === 'success') {
          setFacilityId(closestFacilityId)
          setStateCode(yardStateCode)
        }
      })
    }
    // eslint-disable-next-line
  }, [latitude, longitude])

  useEffect(() => {
    getNavigatorGeolocation(
      function (position) {
        console.log('Latitude is :', position.coords.latitude)
        console.log('Longitude is :', position.coords.longitude)
        setLatitude(position.coords.latitude)
        setLongitude(position.coords.longitude)
        setLocationShared(true)
      },
      (error) => {
        console.error('Error getting location: ', error.message)
        setLocationShared(false)
      }
    )
  }, [])

  useEffect(() => {
    if (facilityId) {
      if (latitude && longitude) {
        setLocationShared(true)
      } else {
        setLocationShared(false)
      }
      const selectedYardInfo = mapData[facilityId]
      setYardLat(selectedYardInfo?.yardLat)
      setYardLong(selectedYardInfo?.yardLong)
      const isUserWithInYard = checkWithRangeForYard(
        {
          lat: latitude,
          long: longitude,
        },
        {
          lat: selectedYardInfo?.yardLat,
          long: selectedYardInfo?.yardLong,
        }
      )
      setLocationMatchingStatus(isUserWithInYard)
      setYardClosedMsg('')
      setYardClosedStatus(true)
      getYardClosedMessage({ facilityId, countryCode: props.countryCode })
        .then((msg) => {
          setYardClosedMsg(msg)
          setYardClosedStatus(false)
        })
        .catch(() => {
          setYardClosedStatus(false)
        })
    } else {
      setYardClosedMsg('')
      setLocationShared(false)
      setLocationMatchingStatus(false)
    }
  }, [facilityId])

  useEffect(() => {
    if (!rawPhoneNumber) {
      setTextAllowed(false)
    } else {
      setTextAllowed(true)
    }
  }, [rawPhoneNumber])

  useEffect(() => {
    setIsMember(equals(userType, propOr('MBR', 'member_type', config)))
  }, [userType, config])

  const getUserTypeOptions = (userTypes) =>
    userTypes
      .filter((type) => type.order) // filter out user types with order as null
      .map((type) => ({
        ...type,
        text: <LocaledText text={type.text} />,
        content: <LocaledText text={type.text} />,
      }))

  const toggleTextAllowed = () => setTextAllowed(!textAllowed)

  const checkForValidLotItems = () => lotInfoItems.every((item) => !isEmpty(item.reasons))

  const getStateOptions = () => {
    if (props.countryCode !== 'ca') return states
    const canadianStatesToSkip = propOr([], 'skip_canadian_states', config)
    return isEmpty(canadianStatesToSkip)
      ? states
      : states.filter(({ value = '' }) => !canadianStatesToSkip.includes(value))
  }

  const areFieldsInValid = () => {
    const basicChecks = userType && firstName && lastName && facilityId && phoneNumber && rawPhoneNumber

    if (STACK === 'uk') {
      return !(basicChecks && !isEmpty(reasonCodes) && checkForValidLotItems())
    }
    return !(
      basicChecks &&
      !isEmpty(reasonCodes) &&
      checkForValidLotItems() &&
      stateCode &&
      (!isMember || (isMember && memberRequestId))
    )
  }

  const handleSubmit = () => {
    const additionalAttributes =
      STACK === 'c' && isMember ? { request_attributes: { member_request_id: memberRequestId } } : {}
    const params = {
      userType,
      firstName,
      lastName,
      reasonCodes,
      phoneNumber: `${dialCode}${rawPhoneNumber}`,
      dialCode,
      numberOfLots,
      textAllowed,
      latitude,
      longitude,
      facilityId,
      stateCode,
      items: lotInfoItems,
      additionalAttributes,
      countryCode: props.countryCode,
    }
    setSubmitPending(true)
    if (!textAllowed) {
      sendEventGA({
        category: 'User',
        action: 'Opted out text messages',
      })
    }
    joinQueue(params).then(({ status, errorMessage, uniqueId }) => {
      setSubmitPending(false)
      if (status === 'success') {
        setSubmitStatus('success')
        uniqueId && props.history.push(`/self-serve/${uniqueId}?country=${props.countryCode}`)
      } else {
        setSubmitStatus('error')
        setFailureMessage(errorMessage)
      }
    })
  }

  return (
    <>
      {isEmpty(userType) ? <LandingBanner /> : <LogoBanner />}
      <div className="selfserve-form-container">
        {renderWholeForm(
          <>
            <WelcomeBanner facilityId={facilityId} stateCode={stateCode} />
            <LocationDetails
              getStateOptions={getStateOptions}
              facilities={facilities}
              stateCode={stateCode}
              setStateCode={setStateCode}
              states={states}
              facilityId={facilityId}
              sendEventGA={sendEventGA}
              setFacilityId={setFacilityId}
              stateMapViewData={STACK === 'uk' ? mapData : mapData[stateCode] || {}}
              countryCode={props.countryCode}
              setMapData={setMapData}
              fetchYardClosedInProgress={fetchYardClosedInProgress}
            />
            {renderIf(yardClosedMsg)(
              <Message error style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="warning" />
                {yardClosedMsg}
              </Message>
            )}

            {renderIf(facilityId && !yardClosedMsg && !locationShared && !skipGeoFence())(
              <Message error style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="warning" />
                {locale('locationNotSharedForQueue')}
              </Message>
            )}
            {renderIf(facilityId && locationShared && !yardClosedMsg && !locationMatchingStatus && !skipGeoFence())(
              <div>
                <ErrorMessage
                  style={{ display: 'flex' }}
                  message={
                    <div>
                      <div> {locale('locationNotMatchingForQueue')} </div>
                      <div>
                        <MapWithBounds
                          isMarkerShown
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geometry,drawing`}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `250px`, margin: '10px 0' }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          markers={markers}
                          theLocation={locationMatchingStatus}
                          googleMaps={googleMaps}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            )}
            <Divider style={dividerStyle} />
          </>
        )}
        <Form className="attached fluid selfServeForm">
          <Form.Group widths="equal">
            <Form.Field>
              <LabelWithRequired labelText="selectUserType" />
              <Dropdown
                fluid
                clearable
                placeholder={locale('selectUserType')}
                selection
                className="userTypeField"
                options={getUserTypeOptions(userTypes)}
                value={userType}
                onChange={(e, { value }) => {
                  setUserType(value)
                  !isEmpty(reasons) && setReasons([])
                }}
              />
            </Form.Field>
          </Form.Group>
          {renderWholeForm(
            <>
              {renderMemberField(
                <Form.Field>
                  <LabelWithRequired labelText="enterMemberId" />
                  <Form.Input
                    fluid
                    placeholder={locale('enterMemberId')}
                    type="text"
                    maxLength={255}
                    value={memberRequestId}
                    onChange={(e, { value }) => setMemberRequestId(value)}
                  />
                </Form.Field>
              )}
              <Form.Group unstackable widths={2}>
                <Form.Field>
                  <Form.Input
                    fluid
                    label={locale('firstName')}
                    placeholder={locale('enterFirstName')}
                    type="text"
                    required
                    maxLength={255}
                    value={firstName}
                    onChange={(e, { value }) => setFirstName(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    fluid
                    label={locale('lastName')}
                    placeholder={locale('enterLastName')}
                    type="text"
                    required
                    maxLength={255}
                    value={lastName}
                    onChange={(e, { value }) => setLastName(value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field className="field-section">
                <LabelWithRequired labelText="enterPhone" />
                <PhoneInput
                  countryCodeEditable={false}
                  onlyCountries={ONLY_COUNTRIES}
                  placeholder={locale('enterPhone')}
                  inputProps={{
                    required: true,
                  }}
                  onKeyDown={(e) => {
                    if (props.countryCode === 'us' && [8, 46].includes(e.which) && phoneNumber.length <= 1)
                      e.preventDefault()
                  }}
                  country={props.countryCode === 'ar' ? 'ae' : props.countryCode} // react-phone-input supports ae as country code for UAE
                  value={phoneNumber}
                  onChange={(phone = '') => {
                    setPhoneNumber(phone)
                  }}
                  onBlur={(e, countryData) => {
                    const phone = e.target.value
                    const parsedPhone = phone && parsePhoneNumberFromString(phone)
                    setPhoneValidity(parsedPhone && parsedPhone.isValid())
                    countryData && setDialCode(countryData.dialCode)
                    parsedPhone && setRawPhoneNumber(parsedPhone.nationalNumber)
                  }}
                />
                {renderIf(STACK === 'c' && !phoneNumberValid)(
                  <div>
                    <Icon name="warning" color="red" />
                    <span className="subtext-label">Please ensure valid phone number is entered with dial code</span>
                  </div>
                )}
              </Form.Field>
            </>
          )}
          {renderWholeForm(
            <>
              <Form.Group widths="equal">
                <Form.Field>
                  <LabelWithRequired labelText="selectReasons" />
                  <Dropdown
                    selection
                    clearable
                    placeholder={locale('selectReasons')}
                    multiple
                    value={reasonCodes}
                    onChange={(e, { value }) => setReasons(value)}
                    renderLabel={({ content }) => ({ content })}
                    options={getLocaledReasons(getFilteredReasons(userType, reasons))}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field className="numberOfLotsField">
                  <LabelWithRequired labelText="numberOfLots" />
                  <Dropdown
                    selection
                    className="dropdownField"
                    placeholder={locale('numberOfLots')}
                    value={numberOfLots}
                    onChange={(e, { value }) => setNumberOfLots(value)}
                    options={numberOfLotsOptions}
                  />
                </Form.Field>
              </Form.Group>
              <LotInfoItems
                userType={userType}
                reasons={reasons}
                userTypes={userTypes}
                setItems={setLotInfoItems}
                items={lotInfoItems}
                reasonCodes={reasonCodes}
                numberOfLots={numberOfLots}
              />
              {renderIf(rawPhoneNumber)(
                <Message attached="bottom">
                  <Form.Checkbox
                    label={locale('textAllowed')}
                    onChange={() => toggleTextAllowed()}
                    checked={textAllowed}
                  />
                  <span className="DisclaimerText">{locale('disclaimerText')}</span>
                </Message>
              )}
            </>
          )}
        </Form>
        {renderIf(submitStatus === 'error')(
          <Message error>
            <Icon name="warning" />
            {failureMessage}
          </Message>
        )}
        <br />
        {renderWholeForm(
          <Button
            fluid
            disabled={
              fetchYardClosedInProgress ||
              Boolean(yardClosedMsg) ||
              areFieldsInValid() ||
              submitPending ||
              (!skipGeoFence() && (!locationShared || !locationMatchingStatus))
            }
            onClick={handleSubmit}
            loading={submitPending}
            primary
          >
            {locale('joinQueue')}
          </Button>
        )}
      </div>
    </>
  )
}
export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(SelfServeForm)
