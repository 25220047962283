// eslint-disable-next-line
import React from 'react'
import { compose, filter, propOr, prop, find, propEq, sortBy, pathOr, flatten, values, isNil, isEmpty } from 'ramda'
import queryString from 'query-string'
import { getPropertyFromLocalStorage } from './storage'
import { isBlank } from './isBlank'

const STACK = process.env.REACT_APP_STACK || 'c'

/**
 *
 * @param {string} userType
 * @param {<Object>} reasons
 * @param {<Object>} userTypes
 */
export const getFilteredReasons = (userType = '', reasons = []) => {
  if (userType) {
    const res = reasons[userType].map(({ code, desc, ...other }) => ({
      key: code,
      text: desc,
      value: code,
      ...other,
    }))
    return sortBy(prop('order'))(res)
  }
  return []
}

/**
 *
 * @param {string} state
 * @param {<Object>} facilities
 * @param {<Object>} states
 * @param {string} stack
 */
export const getFilteredYards = (state = '', facilities = [], states = [], stack = 'c') => {
  if (stack === 'uk') {
    return facilities
  }
  const statesCodes = states.map(prop('key'))
  const filterFacilitiesByState = filter((obj) =>
    statesCodes.includes(state) ? propOr([], 'state', obj).includes(state) : false
  )
  return compose(filterFacilitiesByState)(facilities)
}

/**
 *
 * @param {string} type
 * @param {<Object>} referenceData
 */
export const parseReferenceData = (type, referenceData = []) => {
  switch (type) {
    case 'user_types':
      const userTypes = referenceData.map(({ code, desc, order }) => ({
        key: code,
        text: desc,
        value: code,
        order,
      }))
      return sortBy(prop('order'))(userTypes)
    case 'states':
      return referenceData.map(({ code, desc, ...other }) => ({
        key: code,
        text: STACK === 'c' ? `${code} - ${desc}` : code,
        value: code,
        ...other,
      }))
    case 'facilities':
      const facilities = referenceData.map(({ code, desc, ...other }) => ({
        key: code,
        text: desc,
        value: code,
        ...other,
      }))
      return sortBy(prop('order'))(facilities)
    default:
      console.log('Invalid reference type')
      return referenceData
  }
}

/**
 *
 * @param {string} code
 */
export const getReferenceDataDescription = (code, referenceData) => {
  const data = find(propEq('key', code))(referenceData)
  return propOr(code, 'text', data)
}

export const getReferenceReasonsDescription = (code, referenceData) => {
  const reasonText = compose(propOr(code, 'desc'), find(propEq('code', code)), flatten, values)(referenceData)
  return reasonText
}

export const yesNoOptions = [
  { key: 'Y', text: 'Yes', value: 'Y' },
  { key: 'N', text: 'No', value: 'N' },
]

export const numberOfLotsOptions = new Array(10)
  .fill({ key: '1' })
  .map((o, index) => ({ key: index + 1, value: index + 1, text: index + 1 }))

const formatFacilityOptions = ({
  yard_number: code = '',
  yard_name: name = '',
  yard_state_code: state = '',
  facility_type_code: facilityType = 'Y',
}) => ({
  code,
  desc: facilityType === 'S' ? name : `${name} - ${code}`,
  state,
})

export const constructFacilityData = (response = {}, stack) => {
  let formattedFacilityData = []
  const facilityData = pathOr([], ['data', 'data'], response)
  if (isBlank(facilityData)) {
    return {}
  }
  const mapViewData = facilityData.reduce((acc, currVal) => {
    formattedFacilityData.push(formatFacilityOptions(currVal))

    const {
      yard_number: yardNumber,
      yard_state_code: yardStateCode,
      yard_name: yardName = '',
      yard_address1: addressLine1 = '',
      yard_address2: addressLine2 = '',
      yard_city: city = '',
      yard_zip: zip = '',
      yard_location_0_coordinate: yardLat = '',
      yard_location_1_coordinate: yardLong = '',
      facility_type_code: facilityTypeCode = 'Y',
    } = currVal

    const locationData = {
      [yardNumber]: {
        yardNumber,
        yardStateCode,
        yardName,
        yardLat,
        yardLong,
        facilityTypeCode,
        addressLine1,
        addressLine2,
        city,
        zip,
      },
    }

    const mergeObj =
      stack === 'c'
        ? {
            [yardStateCode]: {
              ...(acc[yardStateCode] || {}),
              ...locationData,
            },
          }
        : {
            ...locationData,
          }

    return {
      ...acc,
      ...mergeObj,
    }
  }, {})

  return {
    mapViewData,
    formattedFacilityData,
  }
}

export const formatAddress = (data = {}) => {
  if (isBlank(data)) {
    return {
      addressSec1: '',
      addressSec2: '',
    }
  }
  const { yardStateCode = '', addressLine1 = '', addressLine2 = '', city = '', zip = '' } = data
  return {
    addressSec1: `${addressLine1}${addressLine2 ? ', ' : ''}${addressLine2}`.trim(),
    addressSec2: `${city}, ${yardStateCode} ${zip}`,
  }
}

export const getMode = () => {
  const search = propOr('', 'search', window.location)
  return propOr('prod', 'mode', queryString.parse(search))
}

export const getCountryFromUrl = (location) => {
  const search = propOr('', 'search', location)
  return propOr('', 'country', queryString.parse(search))
}
export const calculateDistance = (lotLat = 0, lotLng = 0, currentLat, currentLng) => {
  const radlat1 = (Math.PI * lotLat) / 180
  const radlat2 = (Math.PI * currentLat) / 180
  const theta = lotLng - currentLng
  const radtheta = (Math.PI * theta) / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = parseFloat(dist * 60 * 1.1515).toFixed(2)
  return dist
}
/*
 * default range is 1 mile
 */
export const checkWithRangeForYard = (currentPosition, yardPosition, range = 1) => {
  const distance = calculateDistance(yardPosition.lat, yardPosition.long, currentPosition.lat, currentPosition.long)
  return range > distance
}

export const getNavigatorGeolocation = (successCallback, errorCallback = () => {}, params = {}) => {
  if (typeof navigator !== 'undefined' && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, params)
  }
}

export const skipGeoFencing = () => {
  const search = propOr('', 'search', window.location)
  return propOr(false, 'skipgeofence', queryString.parse(search))
}

export const skipGeoFence = () => {
  const res = getPropertyFromLocalStorage('skipgeofence')
  return !isNil(res) && !isEmpty(res)
}
