import React from 'react'
import BrowserRouter from 'react-router-dom/BrowserRouter'

import { LanguageContextProvider } from './context/LanguageContext'
import Main from './pages/Main'

const Router = () => (
  <BrowserRouter>
    <LanguageContextProvider>
      <Main />
    </LanguageContextProvider>
  </BrowserRouter>
)

export default Router
