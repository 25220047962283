export default {
  selectUserType: 'Pick the option that best describes you',
  selectReasons: 'Reason(s) for visit? (Select all that apply)',
  lotIndex: 'Lot Number',
  firstName: 'First name',
  lastName: 'Last name',
  enterFirstName: 'First name',
  enterLastName: 'Last name',
  enterPhone: 'Mobile number',
  selectState: 'Copart location state',
  selectCopartLocation: 'Copart location',
  joinQueue: 'Reserve my place in line',
  removeQueue: 'Remove me from queue',
  cancel: 'Cancel',
  goHome: 'Go back to home page',
  confirmRemoveMessage:
    'You will lose your current position. This action cannot be undone.',
  selfServiceKiosk: 'Self Serve Kiosk',
  unableToJoinQueue: 'Unable to join queue, please try again later.',
  textAllowed: 'Receive Updates via Text Messages',
  disclaimerText:
    'By checking this box, you give Copart permission to text you at the mobile number entered above.',
  thankYouSummary: 'Thank you for your patience.',
  thankYouPremiumMember: 'Thank you for being a Premier Member!',
  currentPosition: 'Your current queue position is',
  willSendText: 'We will text you when a representative',
  nearFrontLine: 'is ready to assist you.',
  summary: 'Summary',
  name: 'Name',
  userType: 'Type of User',
  reason: 'Reason(s) for visit',
  phone: 'Mobile number',
  copartLocation: 'Copart location',
  State: 'Copart location state',
  Province: 'Copart location province',
  lotNumbers: 'Lot',
  createdTime: 'Created Time',
  English: 'English',
  Spanish: 'Spanish',
  French: 'French',
  Russian: 'Russian',
  setupKioskYard: 'Setup kiosk for facility',
  requestStatus: 'Status',
  welcome: 'Welcome!',
  receiveAssistance:
    'Please fill out the information below to reserve your place in line.',
  somethingWentWrong: 'Something went wrong',
  invalidExpiredRequest:
    'Invalid or expired request. Unable to obtain summary information for your request: ',
  thankYouForVisiting: 'Thank you for visiting Copart.',
  requireAssistance:
    'If you require assistance, you may join the queue by clicking the go back to home page button below.',
  lastUpdateTime: 'Last Update: ',
  estimatedWaitTime: 'Estimated wait time',
  readyToAssist:
    'A Copart representative is ready to assist you. Please come to the front counter.',
  waitTimeDisclaimer:
    'Please note that your position in line and wait time may change slightly based on priority in the queue.',
  reportTimeDisclaimer:
    'Please report to the office as soon as you are notified, or your appointment may be cancelled.',
  numberOfLots: 'Number of Lots',
  welcomeTo: 'Welcome to the',
  selfServeKiosk: 'Self Serve Kiosk',
  copartSelfServeKiosk: 'Copart Self Serve Kiosk',
  chooseDesigantionText:
    'Please choose your appropriate designation below to begin.',
  enterMemberId: 'Email or Member Number',
  queue: 'Queue',
  hey: 'Hey',
  fasterService: 'For faster service, enter all Lot Numbers below',
  selectFacilityText: 'Select a facility to view the address',
  allow: 'Allow',
  locationSharing: 'location sharing',
  facilityAutoSelect: 'to enable automatic selection of your nearest facility.',
  nearbyFacilitiesText: 'Nearby locations',
  mapViewHeader: 'Select a Location',
  facilitySelectMapInstruction:
    'Select a state/province to view the respective Copart locations and tap on a location you wish to queue for on the map to select one.',
  selectFacility: 'Select Location',
  locationDetails: 'Location Details',
  viewMap: 'View Map',
  yard: 'Yard',
  sublot: 'Sublot',
  locationsNotFound: 'No locations found!',
  requestSubmitted: 'Request submitted',
  locationNotMatchingForQueue: `Location services shows you are outside of the facility's range. Please ensure you are within the range as outlined in the image below to continue.`,
  locationNotSharedForQueue: 'Please enable location sharing to continue. You can do this by changing your location settings in browser (Settings > Privacy > Location Services).'
}
