import React, { useEffect } from 'react'
import { propOr, pathOr } from 'ramda'
import queryString from 'query-string'

import {
  fetchReferenceData,
  fetchFacilitiesReferenceData,
  fetchKioskConfig,
} from '../actions/selfServeActions'
import { parseReferenceData ,getCountryFromUrl} from '../utils'

export default function ReferenceData(props) {

  const fetchCountrySpecificReferenceData = (countryCode) => {
    fetchFacilitiesReferenceData(countryCode).then(
      ({ formattedFacilityData = [], mapViewData = {} }) => {
        props.setFacilities(
          parseReferenceData('facilities', formattedFacilityData)
        )
        props.setMapData(mapViewData)
      }
    )
    fetchReferenceData('user_types', countryCode).then((data) => {
      props.setUserTypes(parseReferenceData('user_types', data))
    })
    fetchReferenceData('user_type_reasons_map', countryCode).then((data) => {
      props.setReasons(data)
    })
    fetchReferenceData('states', countryCode).then((data) => {
      props.setStatesData(parseReferenceData('states', data))
    })
  }

  useEffect(() => {
    fetchKioskConfig({countryCode:getCountryFromUrl(props.location)})
      .then((data) => props.setKioskConfig(data))
      .catch((error) => {
        // Default config
        console.error('Failed to fetch config')
        props.setKioskConfig({
          transporter_type: 'TRNSPTR',
          member_type: 'MBR',
        })
      })

    // eslint-disable-next-line
  }, [])

  const requestId = pathOr(null, ['match', 'params', 'requestId'], props)

  useEffect(() => {
    // fetching the ref data on going back to the form, from the summary screen
    if (!requestId && getCountryFromUrl(props.location) === props.countryCode) {
      fetchCountrySpecificReferenceData(getCountryFromUrl(props.location))
    }
    // eslint-disable-next-line
  }, [requestId])

  useEffect(() => {
    if (getCountryFromUrl(props.location) !== props.countryCode) {
      props.setCountryCode(getCountryFromUrl(props.location))
      fetchCountrySpecificReferenceData(getCountryFromUrl(props.location))
    }
    // eslint-disable-next-line
  }, [props])

  return <>{props.children}</>
}
