import React from 'react'
import locale from '../../utils/locale'
import { Link } from 'react-router-dom'
import { CopartLogo } from '../../components/Logo'

const LogoBanner = () => (
  <div className="LogoBanner">
    <Link to={`/`} onClick={(event) => event.stopPropagation()}>
      <CopartLogo width="100px" />
    </Link>
    <div className="LogoBannerText">{locale('selfServeKiosk')}</div>
  </div>
)

export default LogoBanner
