import React from 'react'
import { Segment, Modal } from 'semantic-ui-react'
import renderIf from 'render-if'

import { sendEventGA } from '../../utils/sendAnalytics'
import LocaledText from '../../components/LocaledText'
import locale from '../../utils/locale'

const logGAEvent = (actionText) =>
  sendEventGA({ category: 'Banner', action: actionText })

const getLearnMoreUrlForMember = (countryCode) => {
  switch (countryCode) {
    case 'gb':
    case 'ie':
      return 'https://www.copart.co.uk/content/uk/en/buyer/payments/ways-to-pay/ways-to-pay?intcmp=web_uk_reopening_waystopay'
    case 'ar':
    case 'om':
    case 'bh':
      return 'https://www.copartmea.com/en/Content/uk/en/Member-News/MN-US-Payment-Options-in-UAE'
    default:
      return 'https://www.copart.com/content/us/en/buyer/payments/payment-options'
  }
}

const getBannerProps = (userType = '', countryCode) => {
  switch (userType) {
    case 'TRNSPTR':
      return {
        segmentImage: '/images/transporter-banner.png',
        headerText:
          'Schedule your next appointment with the Copart Transportation App!',
        subText: '',
        showBadges: true,
        appStoreLink:
          'https://apps.apple.com/us/app/copart-transportation/id1410332198',
        googlePlayLink:
          'https://play.google.com/store/apps/details?id=com.copart.transportation',
        learnMoreLink:
          'https://www.copart.com/content/us/en/landing-page/copart-transportation',
      }
    case 'MBR':
    case 'PMRMBR':
      return {
        segmentImage: '/images/payment-banner.png',
        headerText: locale('Did you know?'),
        subText: locale('Copart offers several ways to pay online.'),
        showBadges: false,
        appStoreLink: '',
        googlePlayLink: '',
        learnMoreLink: getLearnMoreUrlForMember(countryCode),
      }
    default:
      return {
        segmentImage: '',
        headerText: '',
        subText: '',
        showBadges: false,
        appStoreLink: '',
        googlePlayLink: '',
        learnMoreLink: '',
      }
  }
}

const Link = ({ link, children, actionText = '' }) => (
  <a
    href={link}
    target='_blank'
    rel='noopener noreferrer'
    onClick={() => logGAEvent(actionText)}
  >
    {children}
  </a>
)

const Banner = ({ open, closeBanner, userType, countryCode }) => {
  const {
    segmentImage,
    headerText,
    subText,
    showBadges,
    appStoreLink,
    googlePlayLink,
    learnMoreLink,
  } = getBannerProps(userType, countryCode)

  return (
    <Modal open={open} onClose={() => closeBanner(!open)} closeIcon>
      <Modal.Content>
        <Segment raised className='SegmentBanner'>
          <div
            className='imageHeader'
            style={{ backgroundImage: `url(${segmentImage})` }}
          />
          <div className='SegmentContainer'>
            <div className='header'>{headerText}</div>
            {renderIf(subText)(<div className='subText'>{subText}</div>)}
            {renderIf(showBadges)(
              <div className='badges'>
                <Link
                  link={appStoreLink}
                  actionText='Clicked on Apple App Store'
                >
                  <img
                    alt='Download on the Apple App Store'
                    src={`${process.env.PUBLIC_URL}/images/badges-apple-dark.png`}
                  />
                </Link>
                <Link
                  link={googlePlayLink}
                  actionText='Clicked on Google Play Store'
                >
                  <img
                    alt='Get it on Google Play'
                    src={`${process.env.PUBLIC_URL}/images/badges-google-play-dark.png`}
                  />{' '}
                </Link>
              </div>
            )}
            <div>
              <Link link={learnMoreLink} actionText='Clicked on Learn more'>
                <span className='learnText'>
                  {<LocaledText text='Learn more' />}
                </span>
              </Link>
            </div>
          </div>
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

export default Banner
