import React, { useEffect } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from 'react-google-maps'
import BluePin from '../components/Markers/blue-pin.png'

const MapWithBounds = withScriptjs(
  withGoogleMap((props) => {
    //radius in KM *1000
    const place = {
      id: 1,
      name: 'Facility',
      latitude: props?.markers[0]?.lat,
      longitude: props?.markers[0]?.lng,
      circle: {
        radius: 1000 * 1.60934,
        options: {
          strokeColor: '#3f51b5d1',
          fillColor: '#3f51b5d1',
          strokeWeight: 2,
          fillOpacity: 0.5
        }
      }
    }
    let map = {}
    const fitBounds = () => {
      const bounds = new props.googleMaps.LatLngBounds()
      const setExtendedBounds = props?.markers.forEach((item, idx) => {
        const latlngpoints = new props.googleMaps.LatLng(item?.lat, item?.lng)
        bounds.extend(latlngpoints)
      })
      console.log('latLong markers', props?.markers)
      map.fitBounds(bounds)
    }
    useEffect(() => {
      fitBounds()
    }, [props?.markers])

    return (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={{
          lat: props?.markers[0]?.lat,
          lng: props?.markers[0]?.lng
        }}
        id="googleMap"
        ref={(ref) => {
          map = ref
        }}
        options={{
          mapTypeControl: false,
          streetViewControl: false
        }}
      >
        {props.isMarkerShown &&
          props?.markers?.map((mark, index) => (
            <Marker
              key={index}
              position={mark}
              icon={
                index === 0
                  ? {
                      url: BluePin,
                      scaledSize: new props.googleMaps.Size(30, 43)
                    }
                  : ''
              }
            />
          ))}
        <Circle
          defaultCenter={{
            lat: parseFloat(place.latitude),
            lng: parseFloat(place.longitude)
          }}
          radius={place.circle.radius}
          options={place.circle.options}
        />
      </GoogleMap>
    )
  })
)
export default MapWithBounds
