import React from 'react'
import renderIf from 'render-if'

import Banner from '../../components/Banner'
import { getPropertyFromLocalStorage } from '../../utils/storage'
import locale from '../../utils/locale'

const WelcomeBanner = ({ facilityId, stateCode }) => {
  return (
    <>
      {renderIf(getPropertyFromLocalStorage('facilityId') && getPropertyFromLocalStorage('stateCode'))(
        <Banner facilityId={facilityId} stateCode={stateCode} />
      )}
      <div className="WelcomeMessageForm">
        <div className="WelcomeMessageFormText">{locale('receiveAssistance')}</div>
      </div>
    </>
  )
}

export default WelcomeBanner
