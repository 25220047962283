import React from 'react'
import { Form, Dropdown, Button } from 'semantic-ui-react'
import { view, lensIndex, propOr } from 'ramda'
import renderIf from 'render-if'

import locale from '../utils/locale'
import { getFilteredReasons } from '../utils'
import LocaledText, { getLocaledReasons } from '../components/LocaledText'

type PropsT = {
  items: Array<Object>,
  index: Number,
  userType: String,
  reasons: Array<Object>,
  userTypes: Array<Object>,
  setLotItemInfo: () => void,
  addLotItemInfo: () => void,
  removeLotItemInfo: () => void,
}

const LotItem = ({
  index,
  items,
  userType,
  reasons,
  userTypes,
  setLotItemInfo,
  addLotItemInfo,
  removeLotItemInfo,
  numberOfLots,
}: PropsT) => {
  const isLastOrOnlyItem = index === items.length - 1 && numberOfLots > 1
  const isNotFirstItem = index > 0 || items.length > 1

  const getProperty = (key, index, defaultValue) => {
    const obj = view(lensIndex(index), items)
    return propOr(defaultValue, key, obj)
  }

  const getLabel = () =>
    numberOfLots === 1 ? (
      ''
    ) : (
      <p className='localedLabel'>
        <LocaledText text='lotIndex' /> {index + 1}
      </p>
    )
  const getPlaceHolderLabel = () =>
    numberOfLots === 1
      ? locale('lotIndex')
      : `${locale('lotIndex')} ${index + 1}`

  const hasButtons = isNotFirstItem || isLastOrOnlyItem

  const getFieldProperties = () =>
    hasButtons ? { unstackable: true, width: 2 } : { widths: 'equal' }

  return (
    <>
      <Form.Group className='field-section' {...getFieldProperties()}>
        <Form.Field className={hasButtons ? 'LotItemInputField' : ''}>
          <Form.Input
            label={getLabel()}
            placeholder={getPlaceHolderLabel()}
            value={getProperty('lot_number', index, '')}
            maxLength={8}
            onChange={(e, { value }) => {
              const inputValue = (value || '').replace(/[^\s\d]/g, '')
              setLotItemInfo('lot_number', inputValue, index)
            }}
            type='tel'
          />
        </Form.Field>
        <Form.Field className={hasButtons ? 'LotItemButtonField' : ''}>
          {renderIf(isLastOrOnlyItem)(
            <Button size='mini' circular icon='add' onClick={addLotItemInfo} />
          )}
          {renderIf(isNotFirstItem)(
            <Button
              size='mini'
              circular
              icon='minus'
              onClick={() => removeLotItemInfo(index)}
            />
          )}
        </Form.Field>
      </Form.Group>
      {renderIf(numberOfLots > 1)(
        <Form.Field className='field-section'>
          <Dropdown
            selection
            placeholder={locale('selectReasons')}
            multiple
            value={getProperty('reasons', index, [])}
            onChange={(e, { value }) => setLotItemInfo('reasons', value, index)}
            renderLabel={({ content }) => ({ content })}
            options={getLocaledReasons(
              getFilteredReasons(userType, reasons, userTypes)
            )}
          />
        </Form.Field>
      )}
    </>
  )
}

export default LotItem
