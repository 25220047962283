import React, { useState, createContext } from 'react'
import { getPropertyFromLocalStorage } from '../utils/storage'

const STACK = process.env.REACT_APP_STACK || 'c'

// Create Context Object
export const LanguageContext = createContext()

// Create a provider for components to consume and subscribe to changes
export const LanguageContextProvider = (props) => {
  const defaultLanguage = STACK === 'uk' ? 'en-uk' : 'en'
  const initialLanguage =
    getPropertyFromLocalStorage('language') || defaultLanguage
  const [language, setLanguage] = useState(initialLanguage)

  return (
    <LanguageContext.Provider value={[language, setLanguage]}>
      {props.children}
    </LanguageContext.Provider>
  )
}
