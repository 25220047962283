import React, { useState, useEffect, useRef } from 'react'
import { Divider, Icon, Button } from 'semantic-ui-react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import renderIf from 'render-if'
import { pathOr, values, compose, reduce, filter, isNil } from 'ramda'

import locale from '../../../utils/locale'
import { GOOGLE_API_KEY } from '../../../constants'
import { formatAddress } from '../../../utils'
import BluePin from '../../../components/Markers/blue-pin.png'
import { isBlank } from '../../../utils/isBlank'
import {
  updateMarkerDimensions,
  setMarkerHeight,
  setMarkerWidth,
  getMapPositionProps,
  mapConfig,
} from '../../../utils/mapUtils'
import styles from './styles'

const STACK = process.env.REACT_APP_STACK || 'c'
const YARD_SELECTED_ZOOM_LEVEL = 10

const getFormattedYardName = (facilityType, yardNum, city, facilityName) =>
  facilityType === 'S' ? facilityName : `${yardNum} - ${city || ''}`

const MapView = (props) => {
  const {
    setMapView,
    setSelectedYardData,
    stateMapViewData = {},
    facilityId,
    setFacilityId,
    setStateCode,
    LocationStateComp,
    countryCode,
    stateCode,
  } = props

  const mapRef = useRef({})
  const initialZoom = pathOr(
    4,
    [countryCode, 'zoomLevel', 'initial'],
    mapConfig
  )

  const mapDataAvailable = !isBlank(stateMapViewData)

  const [openToast, setToast] = useState(false)
  const [address, setAddress] = useState({})
  const [selectedYardNum, setSelectedYardNum] = useState(facilityId)
  const [displayYardName, setDisplayYardName] = useState('')
  const [mapMarkers, setMapMarkers] = useState({})
  const [currMapCenter, setCurrMapCenter] = useState({})
  const [currentZoom, setZoom] = useState(initialZoom)
  const [showError, setError] = useState(false)
  const [selectedFacilityTypeCode, setSelectedFacilityTypeCode] = useState('')

  useEffect(() => {
    mapRef.current = {
      ...mapRef.current,
      facilityAlreadySelected: !isBlank(facilityId),
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (stateCode) {
      setError(false)
      if (mapRef.current.stateCode) {
        setToast(false)
        setSelectedYardNum('')
        mapRef.current = {
          ...mapRef.current,
          facilityAlreadySelected: false,
        }
      }
      mapRef.current = {
        ...mapRef.current,
        lastSelectedYardNum: null,
        mapCenterReset: false,
        stateCode,
      }
    }
  }, [stateCode])

  useEffect(() => {
    if (facilityId && !selectedYardNum) {
      setSelectedYardNum(facilityId)
    }
    // eslint-disable-next-line
  }, [facilityId])

  const googleApiAvailable = !isBlank(props.google)
  const markersDataDependency =
    STACK === 'c' ? [stateCode, googleApiAvailable] : [googleApiAvailable]
  const setupMarkers =
    STACK === 'c' ? googleApiAvailable && stateCode : googleApiAvailable

  useEffect(() => {
    if (setupMarkers) {
      setCurrMapCenter({})
      if (!mapDataAvailable) {
        setMapMarkers(null)
      }

      const googleMaps = props.google.maps
      if (googleMaps && mapDataAvailable) {
        const constructMarker = (yardData) => {
          const {
            yardNumber,
            yardLat: lat = 0,
            yardLong: lng = 0,
            facilityTypeCode,
            city = '',
            yardName,
          } = yardData

          // handling the geo location API address auto select case
          const isSelectedYard = selectedYardNum === yardNumber
          if (isSelectedYard) {
            setCurrMapCenter({ lat, lng })
            mapRef.current = {
              ...mapRef.current,
              facilityAlreadySelected: true,
            }
            setAddress(formatAddress(yardData))
            setSelectedFacilityTypeCode(facilityTypeCode)
            setDisplayYardName(
              getFormattedYardName(
                facilityTypeCode,
                selectedYardNum,
                city,
                yardName
              )
            )
          }

          return (
            <Marker
              position={{ lat, lng }}
              onClick={() => onMarkerSelect(yardData)}
              key={`${lat}-${yardNumber}`}
              icon={{
                url: BluePin,
                scaledSize: new props.google.maps.Size(
                  setMarkerWidth(isSelectedYard),
                  setMarkerHeight(isSelectedYard)
                ),
              }}
            />
          )
        }

        const setMarker = (acc, currObj) => ({
          ...acc,
          [currObj.yardNumber]: constructMarker(currObj),
        })

        const filterYardsWithCoordinates = (arr) =>
          !isNil(arr.yardNumber) && !isNil(arr.yardLat) && !isNil(arr.yardLong)

        const markerData = compose(
          reduce(setMarker, {}),
          filter(filterYardsWithCoordinates),
          values
        )(stateMapViewData)

        setMapMarkers(markerData)
      }
    }
    // eslint-disable-next-line
  }, markersDataDependency)

  useEffect(() => {
    if (selectedYardNum) {
      if (!mapRef.current.lastSelectedYardNum) {
        setZoom(YARD_SELECTED_ZOOM_LEVEL)
      }
      if (!isBlank(mapMarkers)) {
        const { lastSelectedYardNum } = mapRef.current
        const resizeOldMarker = Boolean(mapMarkers[lastSelectedYardNum])
        // resizing the markers to highlight the selected one
        setMapMarkers({
          ...mapMarkers,
          ...updateMarkerDimensions(mapMarkers, selectedYardNum, true),
          ...(resizeOldMarker
            ? updateMarkerDimensions(mapMarkers, lastSelectedYardNum)
            : {}),
        })
      }

      mapRef.current = {
        ...mapRef.current,
        lastSelectedYardNum: selectedYardNum,
      }

      const newYardData = pathOr({}, [selectedYardNum], stateMapViewData)
      const { facilityTypeCode, yardName } = newYardData
      setAddress(formatAddress(newYardData))
      setSelectedFacilityTypeCode(facilityTypeCode)
      setDisplayYardName(
        getFormattedYardName(
          facilityTypeCode,
          selectedYardNum,
          newYardData.city,
          yardName
        )
      )
      setSelectedYardData(newYardData)
      setToast(true)
    }
    // eslint-disable-next-line
  }, [selectedYardNum])

  useEffect(() => {
    // resetting the zoom on state change once the map data is available
    if (stateCode) {
      if (!mapDataAvailable) {
        setZoom(initialZoom)
        setError(true)
      }
      if (mapDataAvailable && !selectedYardNum) {
        setZoom(
          pathOr(
            6,
            [countryCode, 'zoomLevel', 'facilitiesAvailable'],
            mapConfig
          )
        )
      }
    }
    // eslint-disable-next-line
  }, [stateCode])

  // calculating the mapCenter on finding the first facility or current center change onDrag/onZoom
  const { mapCenter, initialCenter } = getMapPositionProps(
    countryCode,
    stateMapViewData,
    selectedYardNum,
    currMapCenter
  )

  const updateCurrentCenter = (map = {}) => {
    const lat = pathOr(() => 0, ['center', 'lat'], map)()
    const lng = pathOr(() => 0, ['center', 'lng'], map)()
    if (lat && lng) {
      setCurrMapCenter({
        ...currMapCenter,
        lat,
        lng,
      })
    }
  }

  const onMarkerSelect = (yardData) => {
    const { yardNumber, yardLat: lat = 0, yardLong: lng = 0 } = yardData
    const { lastSelectedYardNum, mapCenterReset, facilityAlreadySelected } =
      mapRef.current || {}

    if (yardNumber === lastSelectedYardNum) return
    // setting map center at the marker, on first marker select, to avoid the marker hiding on toast open
    if (!facilityAlreadySelected && !mapCenterReset) {
      updateCurrentCenter({
        center: {
          lat: () => lat,
          lng: () => lng,
        },
      })
      mapRef.current = {
        ...mapRef.current,
        mapCenterReset: true,
      }
    }
    setSelectedYardNum(yardNumber)
  }

  const resetLocalState = () => {
    setToast(false)
    setAddress({})
    setDisplayYardName('')
    setSelectedFacilityTypeCode('')
    setSelectedYardNum(null)
    mapRef.current = {
      ...mapRef.current,
      lastSelectedYardNum: null,
    }
    setMapMarkers({
      ...mapMarkers,
      ...updateMarkerDimensions(mapMarkers, selectedYardNum),
    })
  }

  const mapContainerClass = `mapContainer toast${openToast ? 'Open' : 'Close'}`

  const facilityLocationText = `${
    selectedFacilityTypeCode === 'S' ? '' : locale('yard')
  } ${displayYardName}`.trim()

  return (
    <>
      <div className='selectFacilityHeader'>
        <div>{locale('mapViewHeader')}</div>
        <Icon
          name='close'
          onClick={() => setMapView(false)}
          style={{ fontSize: '20px' }}
        />
      </div>
      <Divider style={styles.divider} />
      <div className='nonMapSection'>
        <div style={{ lineHeight: 1.43 }}>
          {locale('facilitySelectMapInstruction')}
        </div>
        <LocationStateComp
          className='mapLocationStateSelect'
          showMapViewPlaceholder={true}
          clearable={false}
        />
        <div style={styles.fieldErrContainerStyle}>
          <div style={styles.nearbyFacilities}>
            {locale('nearbyFacilitiesText')}
          </div>
          {renderIf(showError)(
            <div>
              <Icon name='warning' style={styles.errMessage} />
              <span style={styles.errMessage}>
                {locale('locationsNotFound')}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={mapContainerClass}>
        <Map
          google={props.google}
          style={{ height: '100%' }}
          draggable
          scrollwheel
          gestureHandling='greedy'
          mapTypeControl={false}
          streetViewControl={false}
          fullscreenControl={false}
          initialCenter={initialCenter}
          center={mapCenter}
          onDragend={(_, map) => updateCurrentCenter(map)}
          onIdle={(_, map) => {
            if (map.zoom !== currentZoom) {
              updateCurrentCenter(map)
            }
            setZoom(map.zoom)
          }}
          zoom={currentZoom}
        >
          {isBlank(mapMarkers) ? null : values(mapMarkers)}
        </Map>
      </div>
      {renderIf(openToast)(
        <div className='facilityDetailsToast'>
          <span style={{ fontSize: '14px' }}>
            <Icon name='building' style={styles.yardIcon} />
            <span style={styles.yardName}>{facilityLocationText}</span>
            <Icon
              name='close'
              style={styles.toastClose}
              onClick={resetLocalState}
            />
          </span>
          <div
            style={{
              display: 'flex',
              fontSize: '14px',
            }}
          >
            <Icon name='map marker alternate' style={styles.locationIcon} />
            <div style={styles.addressText}>
              <span>{address.addressSec1 || ''}</span>
              <div>{address.addressSec2 || ''}</div>
            </div>
          </div>
          <Button
            style={styles.selectFacilityBtn}
            onClick={() => {
              setMapView(false)
              setFacilityId(selectedYardNum)
              setStateCode(
                pathOr('', [selectedYardNum, 'yardStateCode'], stateMapViewData)
              )
            }}
          >
            {locale('selectFacility')}
          </Button>
        </div>
      )}
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(MapView)
