import axios from 'axios'
import { compose, pathOr, values, map, head, propOr } from 'ramda'

import { constructFacilityData, getMode } from '../utils'
import { getPropertyFromLocalStorage } from '../utils/storage'
import { countryMapper } from '../constants'

const STACK = process.env.REACT_APP_STACK || 'c'

const defaultCountry = STACK === 'c' ? 'USA' : 'GBR'

axios.defaults.headers.common['countrycode'] = defaultCountry
axios.defaults.headers.common['content-type'] = 'application/json'

const YCSMapper = () =>
  ({
    c: 'ycskioskus',
    uk: 'ycskioskuk',
  }[STACK])

function getSource() {
  if (getPropertyFromLocalStorage('facilityId')) return 'Kiosk'
  return 'Mobile'
}

function getCountryCode(countryCode) {
  return countryMapper[countryCode] || defaultCountry
}

export async function joinQueue(params) {
  try {
    const payload = {
      user_type: params.userType,
      first_name: params.firstName,
      last_name: params.lastName,
      phone_number: params.phoneNumber,
      latitude: params.latitude,
      longitude: params.longitude,
      lots_count: params.numberOfLots,
      yard_number: params.facilityId,
      state: params.stateCode,
      source: getSource(),
      is_text_allowed: params.textAllowed ? 'Y' : 'N',
      lots: params.items,
      ...params.additionalAttributes,
    }
    const response = await axios.post(
      `/${YCSMapper()}/kiosk/save_walk_in_request`,
      payload,
      {
        headers: { source: getSource() ,countrycode: getCountryCode(params?.countryCode)},
      }
    )
    const uniqueId = pathOr({}, ['data', 'data', 'uniq_id'], response)
    return { status: 'success', uniqueId }
  } catch ({ response }) {
    console.error('Failed', response)
    return {
      status: 'error',
      errorMessage: compose(head, pathOr([''], ['data', 'message']))(response),
    }
  }
}

export async function fetchRequestData({requestId,countryCode}) {
  try {
    const response = await axios.get(
      `/${YCSMapper()}/kiosk/walk_in_request_summary/${requestId}`,
      {
        headers: { source: getSource(),countrycode: getCountryCode(countryCode)},
      }
    )
    return pathOr({}, ['data', 'data'], response)
  } catch ({ response }) {
    console.error('Failed', response)
    throw response
  }
}

export async function fetchKioskConfig({countryCode}) {
  try {
    const response = await axios.get(`/${YCSMapper()}/kiosk/config`, {
      headers: { source: getSource(),countrycode: getCountryCode(countryCode) },
    })
    return propOr({}, 'data', response)
  } catch ({ response }) {
    console.error('Failed to fetch kiosk config', response)
    throw response
  }
}

export async function leaveQueue({requestId,countryCode}) {
  try {
    const response = await axios.put(
      `/${YCSMapper()}/kiosk/cancel_walk_in_request/${requestId}`,
      {},
      {
        headers: { source: getSource(), countrycode: getCountryCode(countryCode)},
      }
    )
    return response
  } catch ({ response }) {
    console.error('Failed', response)
  }
}

// fetching US user types & reasons ref data for Canada
export async function fetchReferenceData(type, countryCode) {
  try {
    const response = await axios.post(
      `/${YCSMapper()}/kiosk/references`,
      { data_type: type },
      {
        headers: {
          source: getSource(),
          countrycode: getCountryCode(
            countryCode === 'ca' && type !== 'states' ? 'us' : countryCode
          ),
        },
      }
    )
    return type === 'user_type_reasons_map' ? pathOr({}, ['data', 'data', type])(response) : compose(
      map(head),
      values,
      pathOr({}, ['data', 'data', type])
    )(response)
  } catch ({ response }) {
    console.error('Failed', response)
  }
}

export async function fetchClosestYard({latitude, longitude,countryCode}) {
  try {
    const response = await axios.post(
      `/${YCSMapper()}/kiosk/closest_yard`,
      { latitude, longitude, skip_test_yards: getMode() !== 'test' },
      { headers: { source: getSource() ,countrycode: getCountryCode(countryCode)} }
    )
    const defaultValues = [{ yard_number: '', yard_state_code: '' }]
    const {
      yard_number: closestFacilityId,
      yard_state_code: yardStateCode,
    } = compose(head, pathOr(defaultValues, ['data', 'data']))(response)
    return {
      status: 'success',
      closestFacilityId,
      yardStateCode,
    }
  } catch ({ response }) {
    console.error('Failed', response)
    return { status: 'error' }
  }
}

export async function fetchFacilitiesReferenceData(countryCode) {
  try {
    const response = await axios.post(
      `/${YCSMapper()}/kiosk/closest_yard`,
      {
        skip_test_yards: getMode() !== 'test',
      },
      {
        headers: {
          source: getSource(),
          countrycode: getCountryCode(countryCode),
        },
      }
    )
    return constructFacilityData(response, STACK)
  } catch ({ response }) {
    console.error('Failed', response)
    return {}
  }
}

export const getYardClosedMessage = async ({facilityId,countryCode}) => {
  try {
    const response = await axios.get(
      `/${YCSMapper()}/kiosk/kiosk_status/${facilityId}`,
      {
        headers: { source: getSource(),countrycode:getCountryCode(countryCode) },
      }
    )
    return pathOr('', ['data', 'data'], response)
  } catch ({ response }) {
    console.error('Failed', response)
    throw response
  }
}
