export default {
  selectUserType: "Choisissez l'option qui vous décrit le mieux",
  selectReasons:
    "Motif(s) de la visite? ( Sélectionnez tout ce qui s'applique)",
  lotIndex: 'Numéro de lot',
  firstName: 'Prénom',
  lastName: 'Nom',
  enterFirstName: 'Prénom',
  enterLastName: 'Nom de famille',
  enterPhone: 'Numéro de téléphone portable',
  selectState: "L'État de l'emplacement de Copart",
  selectCopartLocation: "L'emplacement de Copart",
  joinQueue: 'Réservez ma place dans la file',
  removeQueue: "Retirez-moi de la file d'attente",
  cancel: 'Annuler',
  goHome: "Retour à la page d'accueil",
  confirmRemoveMessage:
    'Vous allez perdre votre position actuelle. Cette action ne peut être annulée.',
  selfServiceKiosk: 'Kiosque libre-service',
  unableToJoinQueue:
    "Impossible de rejoindre la file d'attente, veuillez réessayer plus tard.",
  textAllowed: 'Recevoir les mises à jour par messages textes',
  disclaimerText:
    'En cochant cette case, vous autorisez Copart à vous envoyer un message texte au numéro de téléphone indiqué ci-dessus.',
  thankYouSummary: 'Merci pour votre patience',
  thankYouPremiumMember: "Merci d'être un membre Premier!",
  currentPosition: "Votre position actuelle dans la file d'attente est",
  willSendText:
    "Nous vous enverrons un message texte lorsqu'un représentant sera présent",
  nearFrontLine: 'est prêt à vous aider',
  summary: 'Sommaire',
  name: 'Nom',
  userType: "Type d'utilisateur",
  reason: 'Motif(s) de la visite',
  phone: 'Numéro de téléphone',
  copartLocation: 'Copart location',
  State: 'État/Province',
  Province: 'Province de localisation de Copart',
  lotNumbers: 'Lot',
  createdTime: 'Heure de la création',
  English: 'Anglais',
  Spanish: 'Espagnol',
  French: 'Français',
  Russian: 'Russe',
  setupKioskYard: "Configurer un kiosque pour l'installation",
  requestStatus: 'État',
  welcome: 'Bienvenue!',
  receiveAssistance:
    "Veuillez remplir les informations ci-dessous pour réserver votre place dans la file d'attente.",
  somethingWentWrong: "Une erreur s'est produite.",
  invalidExpiredRequest:
    "Requête non valide ou expirée. Impossible d'obtenir un résumé des informations pour votre requête",
  thankYouForVisiting: "Merci d'avoir visité Copart.",
  requireAssistance:
    "Si vous avez besoin d'aide, vous pouvez rejoindre la file d'attente en cliquant sur le bouton 'Retour à la page d'accueil' ci-dessous.",
  lastUpdateTime: 'Dernière mise à jour',
  estimatedWaitTime: "Temps d'attente estimé",
  readyToAssist:
    "Un représentant de Copart est prêt à vous aider. Veuillez vous présenter au comptoir d'accueil.",
  waitTimeDisclaimer:
    "Veuillez noter que votre position dans la file d'attente et le temps d'attente peuvent varier légèrement en fonction de la priorité dans la file.",
  reportTimeDisclaimer:
    'Veuillez vous présenter au bureau dès que vous en serez informé, sinon votre rendez-vous pourrait être annulé.',
  numberOfLots: 'Nombre de lots',
  welcomeTo: 'Bienvenue dans',
  selfServeKiosk: 'Kiosque libre-service',
  copartSelfServeKiosk: 'Kiosque libre-service Copart',
  chooseDesigantionText:
    'Veuillez choisir votre désignation appropriée ci-dessous pour commencer.',
  enterMemberId: 'Entrez votre numéro de membre ou votre adresse courriel',
  queue: "File d'attente",
  hey: 'Bonjour',
  fasterService:
    'Pour un service plus rapide, entrez tous les numéros de lot ci-dessous',
  Member: 'Membre',
  Transporter: 'Transporteur',
  'Vehicle Owner': "Propriétaire d'un véhicule",
  Vendor: 'Fournisseur',
  Adjuster: 'Ajusteur',
  Estimator: 'Estimateur',
  'CDS Seller': 'Vendeur CDS',
  'Premier Member': 'Membre Premier',
  Other: 'Autre',
  'Vehicle Inspection': 'Inspection de véhicule',
  'Make Payments': 'Effectuer des paiements',
  'Pickup Vehicles': 'Véhicules de ramassage',
  'Drop off/Pickup Titles or Paperwork':
    'Remettre / ramasser des titres ou des documents',
  'Drop off/Pickup Keys': 'Remise / ramassage des clés',
  'Preview Vehicles': 'Aperçu des véhicules',
  'Pickup Personal Vehicle Items':
    'Ramasser des articles de véhicule personnel',
  'Questions about Membership': "Questions sur l'adhésion",
  'Questions about a Vehicle': 'Questions sur un véhicule',
  'Sign Titles': 'Titres des signes',
  'Pick up checks': 'Ramasser les chèques',
  'Windshield labels': 'Étiquettes de pare-brise',
  selectFacilityText: "Sélectionnez un établissement pour afficher l'adresse",
  allow: 'Autoriser',
  locationSharing: 'partage de position',
  facilityAutoSelect:
    "pour activer la sélection automatique de l'établissement le plus proche.",
  nearbyFacilitiesText: 'Lieux à proximité',
  mapViewHeader: 'Sélectionnez un emplacement',
  facilitySelectMapInstruction:
    'Sélectionnez un état / une province pour afficher les emplacements Copart respectifs et appuyez sur un emplacement pour lequel vous souhaitez faire la queue sur la carte pour en sélectionner un.',
  selectFacility: "Sélectionnez l'emplacement",
  locationDetails: "Détails de l'emplacement",
  viewMap: 'Voir la carte',
  yard: 'Cour',
  sublot: 'Sublot',
  locationsNotFound: 'Aucun emplacement trouvé!',
  requestSubmitted: 'Demande soumise',
}
