export default {
  selectUserType: 'Escoja la opción que mejor lo describa',
  selectReasons:
    'Motivo(s) para la visita? (Seleccione todas las que correspondan)',
  lotIndex: 'Número de lote',
  firstName: 'Nombre',
  lastName: 'Apellido',
  enterFirstName: 'Nombre',
  enterLastName: 'Apellido',
  enterPhone: 'Número de teléfono móvil',
  selectState: 'El estado de la ubicación de Copart',
  selectCopartLocation: 'La ubicación de Copart',
  joinQueue: 'Reservar mi lugar en la fila',
  removeQueue: 'Quitarme de la cola',
  cancel: 'Cancelar',
  goHome: 'Regresar a la página de inicio',
  confirmRemoveMessage:
    'Perderá su posición actual. Esta acción no se puede deshacer.',
  copartSelfServeKiosk: 'Kiosco de autoservicio de Copart',
  selfServiceKiosk: 'Kiosco de autoservicio',
  selfServeKiosk: 'Kiosco de autoservicio',
  unableToJoinQueue:
    'No se pudo unir a la cola, inténtelo nuevamente más tarde.',
  textAllowed: 'Reciba actualizaciones por mensaje de texto',
  disclaimerText:
    'Si marca esta casilla, le otorga permiso a Copart para que le envíe mensajes de texto al número de teléfono que ingresó anteriormente.',
  thankYouSummary: 'Gracias por su paciencia.',
  thankYouPremiumMember: 'Gracias por ser un miembro Premier!',
  currentPosition: 'Su posición actual en la cola es',
  willSendText: 'Le enviaremos un mensaje de texto cuando un representante',
  nearFrontLine: 'esté listo para ayudarlo.',
  summary: 'Resumen',
  name: 'Nombre',
  userType: 'Tipo de usuario',
  reason: 'Motivo(s) para la visita',
  phone: 'Número de teléfono',
  copartLocation: 'Ubicación de Copart',
  State: 'Estado',
  Province: 'Provincia de ubicación de Copart',
  lotNumbers: 'Lote',
  createdTime: 'Hora de creación',
  English: 'Inglés',
  Spanish: 'Español',
  French: 'Francés',
  Russian: 'Ruso',
  setupKioskYard: 'Configurar kiosko para la instalación',
  requestStatus: 'Estado',
  welcome: '¡Bienvenido!',
  welcomeTo: 'Bienvenido al',
  receiveAssistance:
    'Complete la información a continuación para reservar su lugar en la fila.',
  somethingWentWrong: 'Se produjo un error',
  invalidExpiredRequest:
    'Solicitud no válida o caducada. No se pudo obtener la información del resumen para su pedido',
  thankYouForVisiting: 'Gracias por visitar Copart.',
  requireAssistance:
    'Si requiere asistencia, puede unirse a la cola al hacer clic en el botón Regresar a la página de inicio a continuación.',
  lastUpdateTime: 'Última actualización',
  estimatedWaitTime: 'Tiempo de espera estimado',
  minute: 'minute',
  minutes: 'minutes',
  readyToAssist:
    'Un representante de Copart está listo para ayudarlo. Acérquese al mostrador.',
  waitTimeDisclaimer:
    'Tenga en cuenta que su posición en la fila y el tiempo de espera puede cambiar ligeramente en función de la prioridad en la cola.',
  reportTimeDisclaimer:
    'Preséntese en la oficina tan pronto como se le notifique o su cita puede ser cancelada.',
  numberOfLots: 'Número de lotes',
  chooseDesigantionText:
    'Elija su designación apropiada a continuación para comenzar.',
  'Personal Info & Location': 'Personal Info & Location',
  'Reason for Visit': 'Motivo para la visita',
  enterMemberId:
    'Ingrese el número de miembro o dirección de correo electrónico',
  queue: 'Cola',
  hey: 'Ey',
  fasterService:
    'Para un servicio más rápido, ingrese todos los números de lote a continuación',
  selectFacilityText: 'Seleccione una instalación para ver la dirección',
  allow: 'Permitir',
  locationSharing: 'compartir ubicación',
  facilityAutoSelect:
    'para permitir la selección automática de su instalación más cercana.',
  nearbyFacilitiesText: 'Ubicaciones cercanas',
  mapViewHeader: 'Selecciona una ubicación',
  facilitySelectMapInstruction:
    'Seleccione un estado / provincia para ver las respectivas ubicaciones de Copart y toque la ubicación para la que desea hacer cola en el mapa para seleccionar una.',
  selectFacility: 'Seleccionar ubicación',
  locationDetails: 'Detalles de ubicación',
  viewMap: 'Ver el mapa',
  yard: 'Yarda',
  sublot: 'Sublote',
  noLocationsFound: '¡No se encontraron ubicaciones!',
  requestSubmitted: 'Solicitud enviada',
}
