import React, { useEffect, useRef } from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
import renderIf from 'render-if'
import { propOr } from 'ramda'

import locale from '../../utils/locale'
import LabelWithRequired from '../../components/Label'
import { getPropertyFromLocalStorage } from '../../utils/storage'
import FacilityInfo from './FacilityInfo'
import { getFilteredYards } from '../../utils'

const STACK = process.env.REACT_APP_STACK || 'c'

const LocationDetails = (props) => {
  const {
    getStateOptions,
    facilities = {},
    stateCode,
    setStateCode,
    states,
    facilityId,
    sendEventGA,
    setFacilityId,
    stateMapViewData,
    countryCode,
    setMapData,
    fetchYardClosedInProgress = false,
  } = props

  const countryCodeRef = useRef(null)

  useEffect(() => {
    if (countryCode) {
      if (countryCodeRef.current && countryCodeRef.current !== countryCode) {
        setStateCode('')
        setMapData({})
        setFacilityId('')
      }
      countryCodeRef.current = countryCode
    }
    // eslint-disable-next-line
  }, [countryCode])

  const facilityOptions = getFilteredYards(stateCode, facilities, states, STACK)

  const stateOptions = getStateOptions()
  const stateFieldValue =
    stateOptions.length === 1 ? propOr('', 'key', stateOptions[0]) : stateCode
  const disableStateField =
    stateOptions.length === 1 || !!getPropertyFromLocalStorage('stateCode')

  const LocationStateComp = ({
    className = '',
    showMapViewPlaceholder,
    clearable = true,
  }) => {
    const labelText = countryCode === 'ca' ? 'Province' : 'State'
    return (
      <Form.Field className={className}>
        <LabelWithRequired labelText={labelText} />
        <Dropdown
          clearable={clearable}
          disabled={disableStateField}
          placeholder={
            showMapViewPlaceholder
              ? `Select a ${locale(labelText)}`
              : locale(labelText)
          }
          selection
          options={stateOptions}
          value={stateFieldValue}
          onChange={(e, { value }) => {
            setStateCode(value)
            setFacilityId('')
          }}
        />
      </Form.Field>
    )
  }

  return (
    <FacilityInfo
      mapViewProps={{
        stateMapViewData,
        setFacilityId,
        facilityId,
        setStateCode,
        LocationStateComp,
        countryCode,
        stateCode,
      }}
    >
      <Form
        className='attached fluid selfServeForm'
        style={{ marginTop: '15px' }}
      >
        <Form.Group widths='equal'>
          {renderIf(STACK === 'c')(<LocationStateComp />)}
          <Form.Field>
            <LabelWithRequired labelText='selectCopartLocation' />
            <Dropdown
              clearable
              disabled={!!getPropertyFromLocalStorage('facilityId')}
              placeholder={locale('selectCopartLocation')}
              selection
              search
              loading={fetchYardClosedInProgress}
              options={facilityOptions}
              value={facilityId}
              onChange={(e, { value }) => {
                sendEventGA({
                  category: 'User',
                  action: 'Manually selected yard',
                })
                setFacilityId(value)
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </FacilityInfo>
  )
}

export default LocationDetails
