//eslint-disable-next-line
import React from 'react'
import locale from '../utils/locale'

const STACK = process.env.REACT_APP_STACK || 'c'

export const GOOGLE_API_KEY = 'AIzaSyCWbn6Rezs3q5J6ykM215FTfPR5wCVSXhI'

export const getStatusLabelProperties = (status) =>
  ({
    Open: { label: locale('Active'), color: 'green' },
    Processing: { label: locale('Processing'), color: 'green' },
    Cancelled: { label: locale('Cancelled'), color: 'red' },
    Closed: { label: locale('Closed'), color: 'red' },
    Completed: { label: locale('Completed'), color: 'green' },
  }[status] || { label: status, color: 'green' })

export const countryMapper = {
  us: 'USA',
  ca: 'CAN',
  uk: 'GBR',
  ie: 'IRL',
  ar: 'ARE',
  bh: 'BHR',
  om: 'OMN',
}

const getLanguageOptionsUS = () => [
  { key: 'en', text: locale('English'), value: 'en' },
  { key: 'es', text: locale('Spanish'), value: 'es' },
  { key: 'fr', text: locale('French'), value: 'fr' },
  { key: 'ru', text: locale('Russian'), value: 'ru' },
]

const getLanguageOptionsUK = (countryCode) =>
  ['gb', 'ie'].includes(countryCode)
    ? [{ key: 'en-uk', text: locale('English'), value: 'en-uk' }]
    : [
        { key: 'en-uk', text: locale('English'), value: 'en-uk' },
        { key: 'ar', text: locale('Arabic'), value: 'ar' },
      ]

export const getLanguageOptions = (countryCode) =>
  ({
    c: getLanguageOptionsUS(),
    uk: getLanguageOptionsUK(countryCode),
  }[STACK])

const countryOptionsUK = (Component) => [
  {
    key: 'gb',
    text: (
      <Component
        countryCode='gb'
        style={{ height: '22px', width: '28px' }}
        text='GBR'
      />
    ),
    value: 'gb',
  },
  {
    key: 'ie',
    text: (
      <Component
        countryCode='ie'
        style={{ height: '24px', width: '28px' }}
        text='IRE'
      />
    ),
    value: 'ie',
  },
  {
    key: 'ar',
    text: (
      <Component
        countryCode='ar'
        style={{ height: '24px', width: '28px' }}
        text='UAE'
      />
    ),
    value: 'ar',
  },
  {
    key: 'om',
    text: (
      <Component
        countryCode='om'
        style={{ height: '24px', width: '28px' }}
        text='OMN'
      />
    ),
    value: 'om',
  },
  {
    key: 'bh',
    text: (
      <Component
        countryCode='bh'
        style={{ height: '24px', width: '28px' }}
        text='BHR'
      />
    ),
    value: 'bh',
  },
]

const countryOptionsUS = (Component) => [
  {
    key: 'us',
    text: (
      <Component
        countryCode='us'
        style={{ height: '22px', width: '28px' }}
        text='USA'
      />
    ),
    value: 'us',
  },
  {
    key: 'ca',
    text: (
      <Component
        countryCode='ca'
        style={{ height: '22px', width: '28px' }}
        text='CAN'
      />
    ),
    value: 'ca',
  },
]

export const getCountryOptions = (Component) =>
  ({
    c: countryOptionsUS(Component),
    uk: countryOptionsUK(Component),
  }[STACK])

export const ONLY_COUNTRIES = {
  c: ['ca', 'mx', 'us'],
  uk: ['gb', 'ie', 'bh', 'om', 'ae'],
}[STACK]
