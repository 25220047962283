export default {
  selectUserType: 'Выберите вариант, который относится к Вам',
  selectReasons:
    'Причина (-ы) посещения. Выберите все варианты, которые к Вам относятся',
  lotIndex: 'Номер лота',
  firstName: 'Имя',
  lastName: 'Фамилия',
  enterFirstName: 'Имя',
  enterLastName: 'Фамилия',
  enterPhone: 'Номер мобильного телефона',
  selectState: 'Штат площадки Copart',
  selectCopartLocation: 'Площадка Copart',
  joinQueue: 'Забронировать место в очереди',
  removeQueue: 'Удалить из очереди',
  cancel: 'Отменить',
  goHome: 'Вернуться на Главную',
  confirmRemoveMessage:
    'Вы потеряете место в очереди. Его не удастся восстановить.',
  selfServiceKiosk: 'Киоск самообслуживания',
  unableToJoinQueue:
    'Невозможно записаться в очередь. Пожалуйста, попробуйте еще раз позже.',
  textAllowed: 'Получать уведомления через смс-сообщения',
  disclaimerText:
    'Отмечая это поле Вы даете Copart разрешение отправлять Вам сообщения на указанный номер телефона.',
  thankYouSummary: 'Благодарим за терпение',
  thankYouPremiumMember: 'Спасибо за то, что Вы являетесь Премьер-членом!',
  currentPosition: 'Ваше текущее место в очереди -',
  willSendText: 'Мы пришлем сообщение, когда представитель',
  nearFrontLine: 'будет готов Вам помочь.',
  summary: 'Итого',
  name: 'Имя',
  userType: 'Тип пользователя',
  reason: 'Причины (-ы) посещения',
  phone: 'Номер телефона',
  copartLocation: 'Площадка Copart',
  State: 'Штат',
  Province: 'Провинция местоположения Копарт',
  lotNumbers: 'Лот',
  createdTime: 'Время создания',
  English: 'Английский',
  Spanish: 'Испанский',
  French: 'французский',
  Russian: 'Pусский',
  setupKioskYard: 'Установить киоск для площадки',
  requestStatus: 'Статус',
  welcome: 'Добро пожаловать!',
  receiveAssistance:
    'Пожалуйста, заполните информацию ниже, чтобы записаться в очередь.',
  somethingWentWrong: 'Произошла ошибка',
  invalidExpiredRequest:
    'Неверный или просроченный запрос. Не удалось получить информацию по запросу',
  thankYouForVisiting: 'Спасибо, что посетили Copart.',
  requireAssistance:
    'Если Вам необходима помощь, запишитесь в очередь нажав на кнопку возврата на главную страницу.',
  lastUpdateTime: 'Последнее обновление: ',
  estimatedWaitTime: 'Примерное время ожидания',
  readyToAssist:
    'Представитель Copart готов Вам помочь. Пожалуйста, подойдите к главной стойке.',
  waitTimeDisclaimer:
    'Пожалуйста, обратите внимание, что Ваш номер в очереди и время ожидания могут незначительно измениться в зависимости от приоритетности в очереди.',
  reportTimeDisclaimer:
    'Пожалуйста, приходите в офис, как только вы получите уведомление, иначе ваша встреча может быть отменена.',
  numberOfLots: 'Количество лотов',
  welcomeTo: 'Добро пожаловать в',
  selfServeKiosk: 'Киоск самообслуживания',
  copartSelfServeKiosk: 'Киоск самообслуживания Copart',
  chooseDesigantionText: 'Пожалуйста, выберите вариант ниже, чтобы начать.',
  enterMemberId: 'Номер участника или и-мейл',
  queue: 'Очередь',
  hey: 'Привет',
  fasterService:
    'Для более быстрого обслуживания введите все номера лотов ниже',
  selectFacilityText: 'Выберите объект, чтобы просмотреть адрес',
  allow: 'Позволять',
  locationSharing: 'совместное использование местоположения',
  facilityAutoSelect: 'для автоматического выбора ближайшего к вам объекта.',
  nearbyFacilitiesText: 'Ближайшие места',
  mapViewHeader: 'Выберите место',
  facilitySelectMapInstruction:
    'Выберите штат / провинцию, чтобы просмотреть соответствующие местоположения Copart, и нажмите на место, которое вы хотите занять в очереди на карте, чтобы выбрать его.',
  selectFacility: 'Выберите место',
  locationDetails: 'Детали местоположения',
  viewMap: 'Посмотреть карту',
  yard: 'Двор',
  sublot: 'Подлот',
  noLocationsFound: 'Ничего не найдено!',
  requestSubmitted: 'Запрос отправлен',
}
