import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { geolocated } from 'react-geolocated'
import { pathOr } from 'ramda'

import Header from '../components/Header'
import ReferenceData from './ReferenceData'
import QueueStatus from './Summary'
import SelfServeForm from './SelfServeForm'
import FacilityForm from './FacilityForm'
import { initializeGA, sendPageView } from '../utils/sendAnalytics'
import { skipGeoFencing } from '../utils'
import { setPropertyInLocalStorage } from '../utils/storage'

import './style.css'

const STACK = process.env.REACT_APP_STACK || 'c'
const getDefaultCountry = () => {
  switch (STACK) {
    case 'c':
      return window.location.hostname.includes('.com') ? 'us' : 'ca'
    default:
      return 'gb'
  }
}

const Main = (props) => {
  const [countryCode, setCountryCode] = useState()
  const [userTypes, setUserTypes] = useState([])
  const [reasons, setReasons] = useState([])
  const [states, setStatesData] = useState([])
  const [facilities, setFacilities] = useState([])
  const [mapData, setMapData] = useState({})
  const [config, setKioskConfig] = useState({})
  const [windowHeight, setWindowInnerHeight] = useState(window.innerHeight)

  const reportWindowSize = (e) => setWindowInnerHeight(window.innerHeight)

  useEffect(() => {
    initializeGA()
    window.addEventListener('resize', reportWindowSize)
    if (skipGeoFencing()) {
      setPropertyInLocalStorage('skipgeofence', true)
    }
    return () => {
      window.removeEventListener('resize')
    }
  }, [])

  const route = window.location.pathname
  useEffect(() => {
    if (route.includes('self-serve')) {
      const title = route === '/self-serve' ? 'Home page' : 'Summary Page'
      sendPageView(route, 'sendToDefaultTracker', title)
    }
  }, [route])

  const commonProps = {
    config,
    reasons,
    states,
    facilities,
    userTypes,
    countryCode,
    latitude: pathOr('', ['coords', 'latitude'], props),
    longitude: pathOr('', ['coords', 'longitude'], props),
    geoLocationEnabled: pathOr(false, ['isGeolocationEnabled'], props),
    positionError: pathOr({}, ['positionError'], props),
    mapData,
    setMapData,
  }

  const referenceDataProps = {
    countryCode,
    setReasons,
    setFacilities,
    setMapData,
    setUserTypes,
    setStatesData,
    setCountryCode,
    setKioskConfig,
  }

  return (
    <div className="main-container overlay" style={{ height: windowHeight }}>
      <Header countryCode={countryCode} />
      <Switch>
        <Route
          exact
          path="/self-serve"
          render={(routeProps) => (
            <ReferenceData {...routeProps} {...referenceDataProps}>
              <SelfServeForm {...routeProps} {...commonProps} />
            </ReferenceData>
          )}
        />
        <Route
          exact
          path="/settings"
          render={(routeProps) => (
            <ReferenceData {...routeProps} {...referenceDataProps}>
              <FacilityForm
                {...routeProps}
                {...commonProps}
                reasons={reasons}
                states={states}
                facilities={facilities}
                userTypes={userTypes}
              />
            </ReferenceData>
          )}
        />
        <Route
          path="/self-serve/:requestId"
          render={(routeProps) => (
            <ReferenceData {...routeProps} {...referenceDataProps}>
              <QueueStatus
                {...routeProps}
                {...commonProps}
                reasons={reasons}
                states={states}
                facilities={facilities}
                userTypes={userTypes}
              />
            </ReferenceData>
          )}
        />
        <Route path="*">
          <Redirect to={`/self-serve?country=${countryCode || getDefaultCountry()}`} />
        </Route>
      </Switch>
    </div>
  )
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Main)
