import React, { useEffect } from 'react'
import { adjust, assoc, remove } from 'ramda'

import locale from '../utils/locale'

import LotItem from './LotItem'

const DEFAULT_ITEM_ROW = {
  lot_number: '',
  reasons: [],
}

const LotInfoItems = ({ items, setItems, numberOfLots, reasonCodes, ...otherProps }) => {
  useEffect(() => {
    updateItemsBasedOnTotalLots(numberOfLots)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateItemsBasedOnTotalLots(numberOfLots)
    //eslint-disable-next-line
  }, [numberOfLots])

  useEffect(() => {
    setItems(items.map((item) => assoc('reasons', reasonCodes, item)))

    // eslint-disable-next-line
  }, [reasonCodes])

  const getItemObjectToAdd = () => {
    return { ...DEFAULT_ITEM_ROW, reasons: reasonCodes }
  }

  const updateItemsBasedOnTotalLots = (numberOfItems) => {
    if (numberOfItems > items.length) {
      // Add items
      setItems([...items, ...new Array(numberOfItems - items.length).fill(getItemObjectToAdd())])
    } else if (numberOfItems < items.length) {
      // Remove items
      setItems(items.slice(0, numberOfItems))
    }
  }

  const setLotItemInfo = (key, value, index) => {
    setItems(adjust(index, assoc(key, value), items))
  }

  const addLotItemInfo = () => setItems([...items, getItemObjectToAdd()])

  const removeLotItemInfo = (index) => setItems(remove(index, 1, items))

  return (
    <>
      <div className="lotNumberInfo">{locale('fasterService')}</div>
      {items.map((item, index) => (
        <LotItem
          key={index}
          {...otherProps}
          items={items}
          index={index}
          setLotItemInfo={setLotItemInfo}
          addLotItemInfo={addLotItemInfo}
          removeLotItemInfo={removeLotItemInfo}
          numberOfLots={numberOfLots}
        />
      ))}
    </>
  )
}

export default LotInfoItems
